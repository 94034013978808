<template>
  <!--  保险公司-->
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>保险列表</el-breadcrumb-item>
      <el-breadcrumb-item>保险信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <div style="display: flex; align-items: center">
        <el-button style="margin-right: 15px" type="primary" @click="opentan()">添加信息</el-button>
        <!-- <div style="margin: 0 20px 0 20px">关键词:</div>
        <el-input style="width: 12%" placeholder="请输入车牌号" v-model="sosuotext" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="sosuofun()"></el-button>
        </el-input> -->
        <div style="margin: 0 0 0 20px">车牌号:</div>
        <el-select
          @change="idChange"
          style="width: 12%; margin-left: 20px"
          filterable
          remote
          reserve-keyword
          :remote-method="remoteMethod"
          clearable
          :loading="loading"
          placeholder="请选择车牌号"
          v-model="sosuoId"
          class="input-with-select"
        >
          <el-option v-for="item in carOptions" :key="item.id" :label="item.auto_number" :value="item.id" />
        </el-select>
        <div style="margin: 0 0 0 20px">排序:</div>
        <el-select
          @change="sortChange"
          style="width: 12%; margin-left: 20px"
          clearable
          :loading="loading"
          placeholder="请选择排序"
          v-model="sosuoSort"
          class="input-with-select"
        >
          <el-option v-for="item in sortOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <div style="margin: 0 0 0 20px">状态:</div>
        <el-select
          @change="stateChange"
          style="width: 12%; margin-left: 20px"
          clearable
          :loading="loading"
          placeholder="请选择状态"
          v-model="sosuoState"
          class="input-with-select"
        >
          <el-option v-for="item in stateOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <div style="margin: 0 0 0 20px">到期时间:</div>
        <el-date-picker
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="dateChange"
          style="width: 18%; margin-left: 20px"
          v-model="sosuoTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <!-- <div style="display: flex; align-items: center"></div> -->

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="auto_number" label="车牌号"></el-table-column>
        <el-table-column prop="get_in_type.name" label="保险类型"></el-table-column>
        <el-table-column prop="get_in_company.name" label="保险公司"></el-table-column>
        <el-table-column prop="end_date" label="到期时间"></el-table-column>
        <el-table-column prop="valid_status_name" label="状态"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row)"></el-button>

            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog title="车辆保险详情" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="100px" :rules="drules">
        <el-form-item label="车辆" prop="shiyong">
          <div class="el-select">
            <el-autocomplete
              v-model="dform.auto_number"
              @select="openxuanfun"
              :debounce="1000"
              :fetch-suggestions="querySearch"
              placeholder="车辆"
              value-key="auto_number"
            ></el-autocomplete>
          </div>
        </el-form-item>
        <el-form-item label="保险类型" prop="shiyong">
          <el-select v-model="dform.type" placeholder="保险类型">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="保险公司" prop="shiyong">
          <el-select v-model="dform.company_id" placeholder="保险公司">
            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker v-model="dform.start_date" type="date" value-format="yyyy-MM-dd" placeholder="保险开始时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="到期时间">
          <el-date-picker v-model="dform.end_date" type="date" value-format="yyyy-MM-dd" placeholder="保险到期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="保险金额">
          <div class="el-select">
            <el-input @input="dform.money = inputCheck(dform.money)" v-model="dform.money" placeholder="保险金额">
              >
              <i slot="prefix" class="iconfont icon-jiage-copy"></i>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="保单附件" prop="icon">
          <el-upload
            accept=".pdf, .jpg, .jpeg, .png"
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="
              (file, fileList) => {
                handleRemove(file, fileList, this.dform.files)
              }
            "
            :http-request="httpRequest"
            :multiple="false"
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="dform.files"
          >
            <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('保单附件')">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="发票附件" prop="icon">
          <el-upload
            accept=".pdf, .jpg, .jpeg, .png"
            class="upload-demo"
            action=""
            :on-preview="fahandlePreview"
            :on-remove="
              (file, fileList) => {
                handleRemove(file, fileList, this.dform.fapiao)
              }
            "
            :http-request="httpRequest"
            :multiple="false"
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="dform.fapiao"
          >
            <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('发票附件')">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelfun()">取 消</el-button>
        <el-button :disabled="addimgtype" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="imgdialogVisible" width="30%" :before-close="imghandleClose">
      <el-image style="width: 100px; height: 100px" :src="urls"></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="imgdialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <a href="" id="link" target="_search" class="asas"></a>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { AddAutoIns, DelAutoIns, EditAutoIns, GetAutoInsList, GetautoList, GetCompanyList, GetInsurancetypeList } from '../../../api/car'
import { Upimg } from '@/api/upimg'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      urls: '',
      srcList: [],
      imgdialogVisible: false,
      sortOptions: [
        {
          name: '顺序',
          id: 1
        },
        {
          name: '倒序',
          id: 2
        }
      ],
      stateOptions: [
        {
          name: '未启用',
          id: 0
        },
        {
          name: '有效期内',
          id: 1
        },
        {
          name: '已逾期',
          id: 2
        }
      ],
      loading: false,
      carOptions: [],
      sosuoTime: '',
      sosuoSort: '',
      sosuoStart: '',
      sosuoEnd: '',
      sosuoState: '',
      sosuoId: '',
      sosuotext: '',
      addimgtype: false, // 上传控制
      dialogVisible: false, // 弹窗控制
      dform: {
        files: [], // 保单附件
        fapiao: [] // 发票
      },
      // 弹窗按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 节流
      timer: null,
      // 列表数据
      tableData: [],
      carList: [], // 车辆列表
      typeList: [], // 保险类型
      companyList: [] // 保险公司列表
    }
  },
  mounted() {
    this.getautoInslistfun()
    this.getallCar()
  },
  methods: {
       // 金额两位小数正数
    inputCheck(value) {
      return value
        .replace(/^0[0-9]+/, val => val[1])
        .replace(/^(\.)+/, '')
        .replace(/[^\d.]/g, '')
        .replace(/\.+/, '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },

    imghandleClose() {
      this.imgdialogVisible = false
    },
    // 远程搜索下拉合同
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        await this.getallCar(query)
      } else {
        this.carOptions = []
      }
    },
    dateChange(e) {
      console.log(e)
      this.sosuoStart = e[0]
      this.sosuoEnd = e[1]
      console.log(this.sosuoStart, 'sosuoStart')
      this.sosuofun()
    },
    sortChange(e) {
      console.log(e)
      this.sosuofun()
    },
    stateChange(e) {
      console.log(e)
      this.sosuofun()
    },
    idChange(e) {
      console.log(e)
      this.sosuofun()
    },
    async getallCar(key) {
      this.loading = true
      // 获取车辆
      const { data } = await GetautoList({ key: key, list_type: 'all' })
      this.carOptions = data.data.list
      this.loading = false
      // console.log(this.carOptions, ' this.carOptions')
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      // this.getautoInslistfun()
      this.sosuofun()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      // this.getautoInslistfun()
      this.sosuofun()
    },
    editfun(ev) {
      console.log(ev)
      // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      this.dform.money = this.dform.money !== undefined ? this.dform.money / 100 : 0
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) {
      // 打开查看
      console.log(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delautoInsfun(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 搜索
    async sosuofun() {
      const { data } = await GetAutoInsList({
        key: this.sosuotext,
        ...this.pageData,
        auto_id: this.sosuoId,
        sort_type: this.sosuoSort,
        valid_status: this.sosuoState,
        start_time: this.sosuoStart,
        end_time: this.sosuoEnd
      })
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 搜索匹配
    querySearch(queryString, cb) {
      this.getcarpaifun(queryString, cb)

      // 调用 callback 返回建议列表的数据
    },
    // 点击选项后
    openxuanfun(e) {
      this.dform.auto_id = e.id
    },

    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      this.getallfun()
    },
    // eslint-disable-next-line no-unused-vars
    // 删除图片
    handleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    handlePreview(file) {
      console.log(file)
      let url = file.url
      window.open(url, '_blank')
    },
    fahandlePreview(file) {
      console.log(file)
      let url = file.url
      window.open(url, '_blank')
    },
    // eslint-disable-next-line no-unused-vars
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },
    // 图片上传
    async httpRequest(options) {
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      switch (this.steupdata) {
        case '保单附件':
          this.dform.files.push({ name: '保单附件', url: res.data[0].url })
          break
        case '发票附件':
          this.dform.fapiao.push({ name: '发票附件', url: res.data[0].url })

          break
      }
      this.addimgtype = false

      // // 图片上传成功后把图片push到scroll中用于修改
      // console.log(res)
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addautoInsfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editautoInsfun(this.dform)
            }
          })
          console.log(type)
          break
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {
        files: [], // 保单附件
        fapiao: [] // 发票
      }
      this.tantype = 'add'
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取
    async getautoInslistfun() {
      const { data } = await GetAutoInsList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 添加
    async addautoInsfun(v) {
      var newdata = JSON.parse(JSON.stringify(v))
      newdata.money = newdata.money !== undefined ? newdata.money * 100 : 0
      const { data } = await AddAutoIns(newdata)
      this.getanpostfun(data, '添加', this.getautoInslistfun())
    },
    // 删除
    async delautoInsfun(id) {
      const { data } = await DelAutoIns({ id })
      this.getanpostfun(data, '删除', this.getautoInslistfun())
    },
    async editautoInsfun(ev) {
      var newdata = JSON.parse(JSON.stringify(ev))
      newdata.money = newdata.money !== undefined ? newdata.money * 100 : 0
      const { data } = await EditAutoIns(newdata)
      this.getanpostfun(data, '修改', this.getautoInslistfun())
    },
    // 获取车辆搜索
    async getcarpaifun(key, cd) {
      const { data: res } = await GetautoList({ key, list_type: 'all' })
      cd(res.data.list)
    },
    // 获取弹窗所需数据
    async getallfun() {
      // 获取品牌
      const { data: com } = await GetCompanyList({ page: 1, size: 10000 })
      this.companyList = com.data.list
      // 保险类型
      const { data: types } = await GetInsurancetypeList({ page: 1, size: 10000 })
      this.typeList = types.data.info
    },
    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped></style>
