import axios from 'axios'
// 上传图片
export function Upimg(data) {
  return axios({
    url: 'https://storage.haiwojiamei.com/api/meijia/upload',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': '*/*',
      'X-requested-with':'XMLHttpRequest'
    },
    data
  })
}
